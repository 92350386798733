<div fxLayout="row" fxLayoutAlign="left center" style="background-color: #FF0000; min-height: 60px">
    <span style="margin-left: 20px; color: #FFF;">Nittedal IL Ski Værstasjon </span>
</div>

<div class="home" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" style="margin: 10px">
  <div fxLayout="row" fxFlexFill fxLayoutAlign="start center">
    <div fxFlex="50"><a href="http://www.nittedalil.no"><img src="/assets/logo.png" class="logos"></a></div>
    <!-- div fxFlex="50" fxLayoutAlign="end center"><a href="http://www.skiforeningen.no"><img src="/assets/skiforeningen.png" class="logos"></a></div-->
    <div fxFlex="50" fxLayoutAlign="end center"><time class="timo">Sist oppdatert: {{myDate | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}&nbsp;<!--i class="wi wi-cloud-refresh" (click)="fetchData()"></i --></time>
    </div>
  </div>

  <!-- div class="datum" fxLayout="row" fxFlexFill fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <img [src]="image_url" width="100%" />
    </div>
  </div>

  <div class="datum" fxLayout="row" fxFlexFill fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <time class="timo">Sist oppdatert: {{myDate | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}&nbsp;<i class="wi wi-cloud-refresh" (click)="fetchData()"></i></time>
      <br>
    </div>
  </div -->

  <div class="odd" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
    <div class="sectionTitle" fxLayout="row" fxFlexFill fxLayoutAlign="start center">
      <i class="wi wi-thermometer"></i>&nbsp;(ute)
    </div>
    <div fxLayout="row wrap" fxFlexFill fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">Målt: {{minTempDato | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}</div>
        <div class="circle-min">{{ 'Min: ' + minTempGaugeValue + ' ' + minTempGaugeAppendText}}</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">&nbsp;</div>
        <div class="circle-now">{{ 'Nå: ' + tempGaugeValue + ' ' + tempGaugeAppendText}}</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">Målt: {{maxTempDato | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}</div>
        <div class="circle-max">{{ 'Max: ' + maxTempGaugeValue + ' ' + maxTempGaugeAppendText}}</div>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
    <div class="sectionTitle" fxLayout="row" fxFlexFill fxLayoutAlign="start center">
      <i class="wi wi-humidity"></i>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlexFill>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">Målt: {{lastReadingHumidityIn | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}</div>
        <div class="circle-humidlow">{{ 'Inne: ' + humiGaugeValueIn + ' ' + humiGaugeAppendTextIn}}</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">Målt: {{lastReadingHumidityOut | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}</div>
        <div class="circle-humidhigh">{{ 'Ute: ' + humiGaugeValue + ' ' + humiGaugeAppendText}}</div>
      </div>
    </div>
  </div>

  <div class="odd" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
    <div class="sectionTitle" fxLayout="row" fxFlexFill fxLayoutAlign="start center">
      <i class="wi wi-cloudy-gusts"></i>&nbsp;(ute)
    </div>
    <div fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxFlexFill style="padding-bottom: 20px">
      <div fxLayout="column" fxLayoutAlign="start center" style="background-color: darkgray; padding: 20px;">
        <p class="wind">Wind angle: {{ windAngle }}</p>
        <p class="wind">Wind strength: {{ windStrength }}</p>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" style="background-color: #FFF; padding: 20px;">
        <p class="wind">Max wind strength: {{ maxWindStrength }}</p>
        <p class="timo">Målt: {{maxWindStrengthDato | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}</p>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" style="background-color: darkgray; padding: 20px;">
        <p class="wind">Gust angle: {{ gustAngle }}</p>
        <p class="wind">Gust strength: {{ gustStrength }}</p>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
    <div class="sectionTitle" fxLayout="row" fxFlexFill fxLayoutAlign="start center">
      <i class="wi wi-barometer"></i>&nbsp;(ute)
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlexFill>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">Målt: {{myDate | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}</div>
        <div class="circle-pressure">{{ 'Nå: ' + pressGaugeValue + ' ' + pressGaugeAppendText}}</div>
      </div>
    </div>
  </div>

  <div class="odd" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
    <div class="sectionTitle" fxLayout="row" fxFlexFill fxLayoutAlign="start center">
      <i class="wi wi-thermometer"></i>&nbsp;(inne)
    </div>
    <div fxLayout="row wrap" fxFlexFill fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">Målt: {{minTempDatoIn | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}</div>
        <div class="circle-min">{{ 'Min: ' + minTempGaugeValueIn + ' ' + minTempGaugeAppendTextIn}}</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">&nbsp;</div>
        <div class="circle-now">{{ 'Nå: ' + tempGaugeValueIn + ' ' + tempGaugeAppendTextIn}}</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">Målt: {{maxTempDatoIn | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}</div>
        <div class="circle-max">{{ 'Max: ' + maxTempGaugeValueIn + ' ' + maxTempGaugeAppendTextIn}}</div>
      </div>
    </div>
  </div>

  <div [hidden]="hideNoise" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
    <div class="sectionTitle" fxLayout="row" fxFlexFill fxLayoutAlign="start center">
      <i class="wi wi-alien"></i>&nbsp;(inne)
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlexFill>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="timo">Målt: {{myDate | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}</div>
        <div class="circle-noise">{{ 'Nå: ' + noiseGaugeValue + ' ' + noiseGaugeAppendText}}</div>
      </div>
    </div>
  </div>

  <div class="odd" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
    <div class="sectionTitle" fxLayout="row" fxFlexFill fxLayoutAlign="start center"><i class="wi wi-thermometer"></i>&nbsp;Historical temperatures
    </div>
    <div fxLayout="row wrap" fxFlexFill fxLayoutAlign="center center">
      <iframe maxWidth="800px" width="100%" height="500" src="https://datastudio.google.com/embed/reporting/b02cbe5d-1838-43e4-9f7e-5f62dcfdb48c/page/j93hB" frameborder="10" style="border:0" allowfullscreen></iframe>
    </div>
  </div>
</div>
