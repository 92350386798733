import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    moduleSkiStua = '';
    myDate: Date;
    nowDate: Date;
    hideNoise = true;
    image_url = '';

    /*
    Temperature outside
     */
    tempGaugeValue = 0;
    tempGaugeAppendText = '°C';

    minTempGaugeValue = 0;
    minTempGaugeAppendText = '°C';
    minTempDato: Date = new Date();

    maxTempGaugeValue = 0;
    maxTempGaugeAppendText = '°C';
    maxTempDato: Date = new Date();

    /*
     Temperature inside
     */
    tempGaugeValueIn = 0;
    tempGaugeAppendTextIn = '°C';

    minTempGaugeValueIn = 0;
    minTempGaugeAppendTextIn = '°C';
    minTempDatoIn: Date = new Date();

    maxTempGaugeValueIn = 0;
    maxTempGaugeAppendTextIn = '°C';
    maxTempDatoIn: Date = new Date();

    /*
     Humidity outside
     */
    humiGaugeValue = 0;
    humiGaugeAppendText = '%';
    lastReadingHumidityOut: Date;

    /*
     Humidity inside
     */
    humiGaugeValueIn = 0;
    humiGaugeAppendTextIn = '%';
    lastReadingHumidityIn: Date;


    /*
     Atm. Pressure inside
     */
    pressGaugeValue = 0;
    pressGaugeAppendText = 'hPa';


    /*
    Wind
     */
    maxWindStrengthDato: Date;
    maxWindStrength = 0;
    windAngle = 0;
    windStrength = 0;
    gustAngle = 0;
    gustStrength = 0;

    /*
     Noise inside
     */
    noiseGaugeValue = 0;
    noiseGaugeAppendText = 'dB';

    constructor(private http: HttpClient) {
        this.fetchData();
    }


    fetchData(): void {

        this.nowDate = new Date();
        const hours: number = this.nowDate.getHours();

        console.log('hours: ' + hours);

        if (hours > 16 && hours < 22) {
            this.hideNoise = false;
        }

        console.log('hideNoise: ' + this.hideNoise);

        this.getStationData().subscribe(
            (data: any) => {
                console.log('--> got data ' + data['station_name']);
                // main module name
                this.moduleSkiStua = data['station_name'];

                // last data date
                //this.myDate = new Date(data['last_status_store']);
                this.myDate = data['last_status_store'];

                // temperature inside
                this.tempGaugeValueIn = data['tempGaugeValueIn'];
                this.minTempGaugeValueIn = data['minTempGaugeValueIn'];
                //this.minTempDatoIn = new Date(data['minTempDatoIn']);
                this.minTempDatoIn = data['minTempDatoIn'];
                this.maxTempGaugeValueIn = data['maxTempGaugeValueIn'];
                //this.maxTempDatoIn = new Date(data['maxTempDatoIn']);
                this.maxTempDatoIn = data['maxTempDatoIn'];

                // humidity inside
                this.humiGaugeValueIn = data['humiGaugeValueIn'];
                //this.lastReadingHumidityIn = new Date(data['lastReadingHumidityIn']);
                this.lastReadingHumidityIn = data['lastReadingHumidityIn'];

                // pressure
                this.pressGaugeValue = data['pressGaugeValue'];

                // noise
                this.noiseGaugeValue = data['noiseGaugeValue'];

                this.tempGaugeValue = data['tempGaugeValue'];
                this.minTempGaugeValue = data['minTempGaugeValue'];
                //this.minTempDato = new Date(data['minTempDato']);
                this.minTempDato = data['minTempDato'];
                this.maxTempGaugeValue = data['maxTempGaugeValue'];
                //this.maxTempDato = new Date(data['maxTempDato']);
                this.maxTempDato = data['maxTempDato'];

                this.humiGaugeValue = data['humiGaugeValue'];
                //this.lastReadingHumidityOut = new Date(data['lastReadingHumidityOut']);
                this.lastReadingHumidityOut = data['lastReadingHumidityOut'];
                //this.maxWindStrengthDato = new Date(data['maxWindStrengthDato']);
                this.maxWindStrengthDato = data['maxWindStrengthDato'];
                this.maxWindStrength = data['maxWindStrength'];

                this.windAngle = data['windAngle'];
                this.windStrength = data['windStrength'];

                this.gustAngle = data['gustAngle'];
                this.gustStrength = data['gustStrength'];
            },
            (error) => {
                console.log('--> did not get data' + JSON.stringify(error));
            }
        );

        this.getImageData().subscribe(
            (data: any) => {
                console.log('--> got data image' + JSON.stringify(data));
                this.image_url = "https://firebasestorage.googleapis.com/v0/b/weather-nil-services.appspot.com/o/nilskistua-latest.jpg?alt=media&token="+data['downloadTokens'];
            },
            (error) => {
                console.log('--> did not get image data' + JSON.stringify(error));
            }
        );
    }

    getStationData(): Observable<any> {
        const headers = new HttpHeaders().set('content-type', 'application/json');
        return this.http.get<any>('https://storage.googleapis.com/weather-nil-services-netatmo-exchange/latest_weather_data.json', {headers});
    }

    getImageData(): Observable<any> {
        const headers = new HttpHeaders().set('content-type', 'application/json');
        return this.http.get<any>('https://firebasestorage.googleapis.com/v0/b/weather-nil-services.appspot.com/o/nilskistua-latest.jpg', {headers});
    }
}
